// 
// maintenance
// 
.maintenance-bg {
    background-color: #f8f8fb;
}
.maintenance-pages {
    align-items: center;
    display: flex;
    min-height: 100vh;
}
.maintenance-img {
    max-width: 450px;
    margin: auto;
}
.bg-svg {
    background-image: url(../images/svg/shape.svg);
}
.coming-soon-img {
    max-width: 550px;
    margin: auto;
}